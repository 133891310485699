<template>
  <main>
    <div class="flex relative">
      <div class="flex item-center w-full relative">
        <div
          @click="openFilter"
          class="border py-3 flex group w-full items-center px-5 text-xs rounded-md text-gray-500 border-gray-500 font-medium justify-between"
        >
          {{ currentName }}
          <div>
            <svg
              class="w-4 h-4 ml-4 text-gray-600"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.24106 7.7459C4.53326 7.44784 4.99051 7.42074 5.31272 7.66461L5.40503 7.7459L12 14.4734L18.595 7.7459C18.8872 7.44784 19.3444 7.42074 19.6666 7.66461L19.7589 7.7459C20.0511 8.04396 20.0777 8.51037 19.8386 8.83904L19.7589 8.93321L12.582 16.2541C12.2898 16.5522 11.8325 16.5793 11.5103 16.3354L11.418 16.2541L4.24106 8.93321C3.91965 8.60534 3.91965 8.07376 4.24106 7.7459Z"
                fill="#718096"
              />
            </svg>
          </div>
        </div>
        <div
          class="absolute left-0 lg:ml-96 z-50 date-picker"
          v-if="toggleDatePicker"
        >
          <date-picker
            @click.stop
            ref="datepicker"
            v-model="custom"
            class="shadow-md rounded-md"
            :inline="true"
            :disabled-date="(date) => date >= new Date()"
            format="YYYY-MM-DD"
            range
          ></date-picker>
        </div>
      </div>

      <div
        v-show="toggleFilter"
        style="top: 3.5rem; max-height: 20rem; z-index: 20"
        class="lg:w-96 w-full text-sm absolute custom-scrollbar left-0 h-auto overflow-y-auto bg-white rounded-md shadow-md border border-gray-200"
      >
        <div class="flex my-5">
          <div class="flex-1">
            <h1
              class="text-sm text-center text-gray-900 text-opacity-75 uppercase cursor-pointer group-hover:text-primaryBlue leading-6 font-medium"
            >
              Date Filter
            </h1>
            <div class="flex flex-col mt-4">
              <div class="flex items-center py-2 px-5 hover:bg-gray-200">
                <input
                  id="today"
                  type="radio"
                  @click.passive="revertCustom('Today')"
                  v-model="computedFilterBy"
                  :value="{
                    startDate: moment().format('YYYY-MM-DD'),
                    endDate: moment().format('YYYY-MM-DD'),
                  }"
                />
                <label
                  for="today"
                  class="text-sm text-gray-700 text-opacity-75 uppercase cursor-pointer group-hover:text-primaryBlue ml-2 leading-6 font-medium"
                >
                  Today
                </label>
              </div>
              <div class="flex items-center py-2 px-5 hover:bg-gray-200">
                <input
                  @click.passive="revertCustom('Yesterday')"
                  id="yesterday"
                  type="radio"
                  v-model="computedFilterBy"
                  :value="{
                    startDate: moment()
                      .subtract(1, 'days')
                      .startOf('day')
                      .format('YYYY-MM-DD'),
                    endDate: moment()
                      .subtract(1, 'days')
                      .endOf('day')
                      .format('YYYY-MM-DD'),
                  }"
                />
                <label
                  for="yesterday"
                  class="text-sm text-gray-700 text-opacity-75 uppercase cursor-pointer group-hover:text-primaryBlue ml-2 leading-6 font-medium"
                >
                  Yesterday
                </label>
              </div>
              <div class="flex items-center py-2 px-5 hover:bg-gray-200">
                <input
                  @click.passive="revertCustom('Past Week')"
                  id="pastWeek"
                  type="radio"
                  v-model="computedFilterBy"
                  :value="{
                    startDate: moment()
                      .subtract(1, 'week')
                      .format('YYYY-MM-DD'),
                    endDate: moment().format('YYYY-MM-DD'),
                  }"
                />
                <label
                  for="pastWeek"
                  class="text-sm text-gray-700 text-opacity-75 uppercase cursor-pointer group-hover:text-primaryBlue ml-2 leading-6 font-medium"
                >
                  Past Week
                </label>
              </div>
              <div class="flex items-center py-2 px-5 hover:bg-gray-200">
                <input
                  @click.passive="revertCustom('This Month')"
                  id="thisMonth"
                  type="radio"
                  v-model="computedFilterBy"
                  :value="{
                    startDate: moment().startOf('month').format('YYYY-MM-DD'),
                    endDate: moment().format('YYYY-MM-DD'),
                  }"
                />
                <label
                  for="thisMonth"
                  class="text-sm text-gray-700 text-opacity-75 uppercase cursor-pointer group-hover:text-primaryBlue ml-2 leading-6 font-medium"
                >
                  This month
                </label>
              </div>
              <div class="flex items-center py-2 px-5 hover:bg-gray-200">
                <input
                  @click.passive="revertCustom('Last Month')"
                  id="lastMonth"
                  type="radio"
                  v-model="computedFilterBy"
                  :value="{
                    startDate: moment()
                      .subtract(1, 'months')
                      .startOf('month')
                      .format('YYYY-MM-DD'),
                    endDate: moment()
                      .subtract(1, 'months')
                      .endOf('month')
                      .format('YYYY-MM-DD'),
                  }"
                />
                <label
                  for="lastMonth"
                  class="text-sm text-gray-700 text-opacity-75 uppercase cursor-pointer group-hover:text-primaryBlue ml-2 leading-6 font-medium"
                >
                  Last month
                </label>
              </div>
              <div class="flex items-center py-2 px-5 hover:bg-gray-200">
                <p
                  @click="toggleDatePicker = !toggleDatePicker"
                  class="text-sm text-opacity-75 uppercase cursor-pointer text-primaryBlue group-hover:text-primaryBlue ml-2 leading-6 font-medium"
                >
                  Custom Date Filter
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import ClickOutside from 'vue-click-outside';
import moment from 'moment';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  props: ['value', 'currentName'],
  data() {
    return {
      toggleFilter: false,
      moment,
      selectedStatus: ['pending', 'paid', 'received'],
      assign: {
        startDate: '',
        endDate: '',
      },
      toggleDatePicker: false,
      custom: null,
    };
  },
  directives: {
    ClickOutside,
  },
  components: {
    DatePicker,
  },

  watch: {
    selectedStatus: {
      deep: true,
      immediate: true,
      handler(value) {
        this.$emit('currentStatus', value);
      },
    },
    custom: {
      deep: true,
      handler(value) {
        if (value) {
          this.assign.startDate = moment(value[0]).format('YYYY-MM-DD');
          this.assign.endDate = moment(value[1]).format('YYYY-MM-DD');
          this.$emit('input', this.assign);
          this.$emit(
            'updateName',
            `Custom:\u00A0  ${moment(value[0]).format(
              'DD/MMM/YYYY'
            )}\u00A0\u00A0    - \u00A0\u00A0    ${moment(value[1]).format(
              'DD/MMM/YYYY'
            )}`
          );
          this.toggleFilter = false;
          this.toggleDatePicker = false;
        }
      },
    },
  },

  computed: {
    computedFilterBy: {
      get() {
        return this.value;
      },
      set(range) {
        this.$emit('input', range);
      },
    },
  },

  methods: {
    close() {
      this.toggleFilter = false;
      this.toggleDatePicker = false;
    },
    openFilter() {
      if (this.toggleDatePicker) {
        this.toggleDatePicker = false;
      }
      this.toggleDatePicker = false;
      this.toggleFilter = !this.toggleFilter;
    },
    revertCustom(name) {
      this.$emit('updateName', name);
      if (this.toggleDatePicker) {
        this.toggleDatePicker = false;
      }
      this.assign.startDate = '';
      this.assign.endDate = '';
      this.custom = null;
      this.toggleFilter = false;
    },
  },
};
</script>

<style>
.date-picker {
  right: -32rem !important;
  top: 3.52rem !important;
}

.mx-datepicker-main {
  border-radius: 0.375rem !important;
}
</style>
