<template>
  <trac-loading v-if="loading" />
  <div v-else class="select-network">
    <div
      class="h-96 flex flex-col justify-center items-center service-provider-hold"
    >
      <h3 class="mb-3">Select your service provider</h3>
      <div class="grid grid-cols-4 gap-2 md:gap-6 shadow-md px-8 py-2">
        <div v-for="(network, index) in networks" class="mt-4 cursor-pointer" :key="index">
          <input
            class="hidden"
            type="radio"
            name="service-provider"
            v-model="selectedNetwork"
            :value="network.ServiceID"
          />
          <img
            class="p-2 w-16 h-16"
            @click="selectedNetwork = network.ServiceID"
            :src="network.Logo"
            alt=""
          />
        </div>
      </div>
    </div>
    <div class="flex justify-end">
      <trac-button
        @button-clicked="setServiceProvider"
        :disabled="selectedNetwork === ''"
        >CONTINUE</trac-button
      >
    </div>
  </div>
</template>

<script>
export default {
  // name: 'NetworkSelection'
  data() {
    return {
      selectedNetwork: '',
      networks: [],
      loading: false,
    }
  },
  methods: {
    setServiceProvider() {
      this.$emit('set-service-provider', {
        serviceProvider: this.selectedNetwork,
      })
    },

    async fetchProviders() {
      try {
        const response = await this.$store.dispatch('FETCH_PROVIDERS')
        return response.data.Categories
      } catch (error) {
        console.log(error)
      }
    },
  },
  async mounted() {
    this.loading = true
    const res = await this.fetchProviders()
    this.networks = res.Airtime
    this.loading = false
  },
}
</script>

<style lang="scss" scoped>
.service-provider-hold {
  input:checked ~ img {
    border: 0.1rem solid #003283;
    border-radius: 0.3rem;
  }
}
</style>
