<template>
  <div class="md:w-9/12 mx-auto px-2 md:px-8 py-12">
    <trac-loading v-if="isLoading" />
    <h3 class="my-5">Buy Airtime and Data</h3>
    <div class="flex justify-between">
      <div
        class="bg-accentLight rounded-md px-4 py-2 flex justify-evenly items-center gap-3"
      >
        <input
          type="radio"
          name="transaction-type"
          id="airtime"
          class="cursor-pointer"
          v-model="transactionType"
          value="airtime"
        />
        <label for="airtime" class="cursor-pointer text-xs"> Airtime </label>
        <img
          class="p-2"
          src="./../../../../assets/svg/wallet/tablet.svg"
          alt=""
        />
      </div>
      <div
        class="bg-accentLight rounded-md px-4 py-2 flex justify-evenly items-center gap-3"
      >
        <input
          type="radio"
          name="transaction-type"
          id="data"
          class="cursor-pointer"
          v-model="transactionType"
          value="data"
        />
        <label for="data" class="cursor-pointer text-xs"> Data Plan </label>
        <img
          class="p-2"
          src="./../../../../assets/svg/wallet/globe.svg"
          alt=""
        />
      </div>
    </div>
    <div class="flex justify-center my-4">
      <img class="p-2" alt="" :src="selectedNetwork.img" width="100" />
      <!-- src="./../../../../assets/svg/wallet/9mobile_logo.svg" -->
    </div>
    <div class="my-4">
      <label for="phone-number" class="inline-block mb-2 text-xs"
        >Type in mobile number</label
      >
      <trac-input
        type="number"
        v-model="phoneNumber"
        id="phone-number"
      ></trac-input>
      <br />
      <div class="" v-if="transactionType === 'data'">
        <label for="phone-number" class="inline-block mb-2 text-xs"
          >Select your data plan</label
        >
        <trac-dropdown-exteneded
          :neededProperty="'name'"
          :options="plans"
          :selector="'Data plan'"
          @optionSelected="selectDataPlan($event)"
        ></trac-dropdown-exteneded>
      </div>
      <div class="" v-else>
        <label for="phone-number" class="inline-block mb-2 text-xs"
          >How much do you want to Top-up?</label
        >
        <trac-input
          type="number"
          v-model.number="enteredVoucherAmount"
          id=""
        ></trac-input>
        <div v-if="enteredVoucherAmount" class="text-xs text-right text-red-600">
          <span v-if="!withinLimits.valid">{{ withinLimits.reason }}</span>
        </div>
      </div>
    </div>
    <div class="flex justify-end mt-8">
      <trac-button
        :disabled="disabled"
        @button-clicked="selectTypeOfTransaction"
        class=""
        >Proceed to payment</trac-button
      >
    </div>
  </div>
</template>

<script>
import PaymentLimits from '../../PaymentLimits'
export default {
  props: ['selectedNetwork'],
  mixins: [PaymentLimits],
  data() {
    return {
      isLoading: false,
      transactionType: 'airtime',
      planSelected: '',
      enteredVoucherAmount: '',
      phoneNumber: '',
      plans: [],
    }
  },
  async created() {
    // console.log(`./../../../../${this.selectedNetwork.img}`);
    const response = await this.$store.dispatch(
      'FETCH_PACKAGE',
      `${this.selectedNetwork.serviceProvider}-data`
    )
    this.plans = response.data.content.variations

    // console.log(response, 'smith')
  },
  watch: {
    transactionType: async function () {
      if (this.plans.length === 0) {
        this.isLoading = true
        await this.fetchTariff()
        this.isLoading = false
      }
    },
  },
  computed: {
    withinLimits() {
      if (this.transactionType === 'airtime') {
        return this.checkAirtimeLimits(this.enteredVoucherAmount)
      } else {
        return {valid: true, reason:''}
      }
    },
    disabled() {
      if (this.transactionType === 'airtime') {
        return (
          this.phoneNumber === '' ||
          this.enteredVoucherAmount === '' ||
          this.phoneNumber.length < 11 ||
          this.enteredVoucherAmount <= 1 || !this.withinLimits.valid
        )
      } else {
        return (
          this.phoneNumber === '' ||
          this.phoneNumber.length < 11 ||
          this.planSelected === ''
        )
      }
    },
  },
  methods: {
    async fetchProviders() {
      try {
        const response = await this.$store.dispatch('FETCH_PROVIDERS')
        return response?.data?.Categories
      } catch (error) {
        console.log(error)
      }
    },
    async fetchTariff() {
      const res = await this.$store.dispatch(
        'FETCH_PACKAGE',
        `${this.selectedNetwork.serviceProvider}-data`
      )

      this.plans = res.data.content.variations
    },
    selectDataPlan(planSelected) {
      this.planSelected = planSelected
    },
    selectTypeOfTransaction() {
      const transactionData = {
        transactionType: this.transactionType,
        phoneNumber: this.phoneNumber,
        service: this.selectedNetwork,
        planSelected:
          this.transactionType === 'airtime'
            ? +this.enteredVoucherAmount
            : this.planSelected,
      }
      this.$emit('select-type-of-transaction', transactionData)
    },
  },
}
</script>

<style></style>
